import { ClaimMethod } from '@paid-ui/enums/claim';
import { type ContractTemplate, ContractType } from '@paid-ui/enums/contract';
import { reloadContract } from '@paid-ui/models/contract';
import { reloadProfile } from '@paid-ui/models/user';
import { isNil } from 'lodash';
import Router from 'next/router';

import {
  flyoutManager,
  FlyoutType,
  globalSettingsDialogs,
  initialState,
  NestedFlyoutType,
} from './model';

export const newContractQueryMap = {
  [ContractType.HEAD_CONTRACT]: 'new-contract',
  [ContractType.SUBCONTRACT]: 'new-subcontract',
  [ContractType.SUPPLY_CONTRACT]: 'new-supply-contract',
};

export const openFlyout = (
  type: FlyoutType,
  details?: Partial<Omit<typeof initialState, 'open' | 'stack'>>,
) => {
  const { nestedOpen } = flyoutManager;

  Object.assign(flyoutManager, initialState, {
    open: true,
    type,
    // FIXME: This is a workaround
    // Save contract draft will close checkout nested flyout
    // Because of the router query changed after draft saved
    nestedOpen:
      type === FlyoutType.SUPER_CONTRACT && details?.details?.contractId
        ? nestedOpen
        : details?.nestedOpen,
    ...details,
  });
};

export const closeFlyout = () => {
  Object.assign(flyoutManager, initialState);
};

export const openNestedFlyout = (
  type?: NestedFlyoutType,
  nestedDetails?: Record<string, unknown>,
) => {
  const { open } = flyoutManager;
  if (!open) return;
  flyoutManager.nestedOpen = true;
  flyoutManager.nestedType = type || NestedFlyoutType.NONE;
  flyoutManager.nestedDetails = nestedDetails ?? {};
};

export const closeNestedFlyout = () => {
  flyoutManager.nestedOpen = false;
  flyoutManager.nestedType = NestedFlyoutType.NONE;
};

export const saveFlyoutState = (type: FlyoutType, data?: Record<string, unknown> | null) => {
  flyoutManager.stack.push({
    type,
    pathname: window.location.pathname,
    search: new URLSearchParams(window.location.search).toString(),
    data,
  });
};

export const getFlyoutState = (type?: FlyoutType) => {
  const flyout = flyoutManager.stack.pop();
  if (!flyout) return null;
  if (type && flyout.type !== type) return null;
  return flyout;
};

export const restoreFlyout = () => {
  const previousFlyout = flyoutManager.stack.at(-1);
  if (!previousFlyout) return;
  flyoutManager.stack.pop();
  const { pathname, search } = previousFlyout;
  Router.replace([pathname, search].join('?'), undefined, {
    shallow: true,
  });
};

const openDialogFromUrl = (dialog: string, query?: Record<string, any>, removed?: string[]) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('dialog', dialog);

  if (removed && removed.length > 0) {
    removed.forEach((key) => {
      urlSearchParams.delete(key);
    });
  }

  if (query) {
    Object.entries(query)
      .filter(([_, value]) => !isNil(value))
      .forEach(([key, value]) => {
        if (value) {
          urlSearchParams.set(key, value);
        }
      });
  }

  urlSearchParams.sort();
  Router.replace(
    {
      pathname: window.location.pathname,
      search: urlSearchParams.toString(),
    },
    undefined,
    {
      shallow: true,
      scroll: false,
    },
  );
};

export const openApplyAccountDialog = () =>
  openDialogFromUrl('apply-account', undefined, ['claim', 'claimId']);
export const openTransactionDetailsDialog = (options?: {
  transactionId?: string;
  subTab?: string;
}) => openDialogFromUrl('transaction-details', options);

export const openNewProjectDialog = () => openDialogFromUrl('new-project');
export const openEditProjectDialog = () => openDialogFromUrl('edit-project');

export const openNewContractDialog = (
  contractType: ContractType = ContractType.HEAD_CONTRACT,
  options?: {
    contractTemplate?: ContractTemplate | '';
    claimMethod?: ClaimMethod;
    isDraft?: boolean;
  },
) => {
  const { contractTemplate, claimMethod, isDraft } = options ?? {};
  openDialogFromUrl(newContractQueryMap[contractType], {
    template: contractTemplate?.toLowerCase(),
    method: claimMethod?.toLowerCase(),
    draft: isDraft ? '1' : '0',
  });
};

export const openAmendContractDialog = (
  contractType: ContractType = ContractType.HEAD_CONTRACT,
  options?: {
    contractTemplate?: ContractTemplate;
    claimMethod?: ClaimMethod;
  },
) => {
  const { contractTemplate, claimMethod } = options ?? {};
  openDialogFromUrl('amend-contract', {
    contractType,
    template: contractTemplate,
    method: claimMethod,
  });
};

export const openReviewContractDialog = (
  contractType: ContractType = ContractType.HEAD_CONTRACT,
  options?: {
    contractTemplate?: ContractTemplate;
    claimMethod?: ClaimMethod;
  },
) => {
  const { contractTemplate, claimMethod } = options ?? {};
  openDialogFromUrl('review-contract', {
    contractType,
    template: contractTemplate,
    method: claimMethod,
  });
};

export const openReinviteDialog = () => openDialogFromUrl('resend-invite');
export const openReinviteSuperintendentDialog = () => openDialogFromUrl('reinvite-superintendent');
export const openReinviteArchitectDialog = () => openDialogFromUrl('reinvite-architect');

export const openContractDetailsDialog = (options?: { contractId?: string; subTab?: string }) =>
  openDialogFromUrl('contract-details', options);

export const openSignContractDialog = (options: {
  contractId: string;
  contractTemplate?: ContractTemplate;
}) => openDialogFromUrl('sign-contract', options);
export const openContractNavigatorDialog = (options: { contractId: string }) =>
  openDialogFromUrl('contract-navigator', options);

export const openNewStagedClaimDialog = (options?: { paymentId?: string; isDraft?: boolean }) =>
  openDialogFromUrl(
    'new-claim',
    {
      method: ClaimMethod.STAGED.toLowerCase(),
      paymentId: options?.paymentId,
      draft: options?.isDraft ? '1' : undefined,
    },
    ['subTab'],
  );

export const openAmendStagedClaimDialog = (options: { paymentId: string }) => {
  openDialogFromUrl(
    'amend-claim',
    {
      method: ClaimMethod.STAGED.toLowerCase(),
      paymentId: options.paymentId,
    },
    ['subTab'],
  );
};

export const openReviewStagedClaimDialog = (options: { paymentId: string }) => {
  openDialogFromUrl(
    'review-claim',
    {
      method: ClaimMethod.STAGED.toLowerCase(),
      paymentId: options.paymentId,
    },
    ['subTab'],
  );
};

export const openNewPeriodicClaimDialog = (options?: { isProvisional?: boolean }) =>
  openDialogFromUrl('new-claim', {
    method: ClaimMethod.PERIODIC.toLowerCase(),
    provisional: options?.isProvisional ? '1' : undefined,
  });

export const openUpdateConsolidatedClaimDialog = (options?: { claimId?: string }) =>
  openDialogFromUrl('update-claim', options, ['subTab']);

export const openSecurePaymentDialog = (options?: { claimId?: string; paymentId?: string }) => {
  openDialogFromUrl('secure-payment', options, ['subTab']);
};

export const openSecureVariationDialog = (options?: { variationId?: string }) => {
  openDialogFromUrl('secure-variation', options, ['subTab']);
};

export const openPayClaimDialog = (options?: { claimId?: string }) => {
  openDialogFromUrl('pay-claim', options, ['subTab']);
};

export const openEarlyReleaseDialog = (options?: { claimId?: string }) => {
  openDialogFromUrl('earlyrelease', options, ['subTab']);
};

export const openReviewOfferDialog = (options?: { claimId?: string }) => {
  openDialogFromUrl('earlyrelease-review-offer', options, ['subTab']);
};

export const openAllClaimsDialog = () => openDialogFromUrl('all-claims');
export const openClaimRetentionDialog = () => openDialogFromUrl('new-claim-retention');
export const openLinkedPaymentsDialog = (options?: { paymentId?: string }) =>
  openDialogFromUrl('linked-payments', options, ['subTab', 'transactionId']);
export const openPaymentDetailsDialog = (options?: { paymentId?: string; contractId?: string }) =>
  openDialogFromUrl('payment-details', options, [
    'subTab',
    'transaction',
    'transactionId',
    'variation',
    'variationId',
    'adjustment',
    'adjustmentId',
  ]);

export const openClaimDetailsDialog = (options?: {
  claimId?: string;
  contractId?: string;
  subTab?: string;
}) =>
  openDialogFromUrl('claim-details', options, [
    'subTab',
    'transaction',
    'transactionId',
    'variation',
    'variationId',
    'adjustment',
    'adjustmentId',
  ]);

export const openNewVariationDialog = (options?: { claimMethod?: ClaimMethod }) => {
  const { claimMethod } = options ?? {};
  openDialogFromUrl('new-variation', {
    method: claimMethod?.toLowerCase(),
  });
};

export const openAmendVariationDialog = (options?: {
  variationId: string;
  claimMethod?: ClaimMethod;
}) => {
  const { variationId, claimMethod } = options ?? {};
  openDialogFromUrl('amend-variation', {
    method: claimMethod?.toLowerCase(),
    variationId,
  });
};

export const openReviewVariationDialog = (options?: {
  variationId: string;
  claimMethod?: ClaimMethod;
}) => {
  const { variationId, claimMethod } = options ?? {};
  openDialogFromUrl(
    'review-variation',
    {
      method: claimMethod?.toLowerCase(),
      variationId,
    },
    ['variation-details'],
  );
};

export const openAllVariationsDialog = () => openDialogFromUrl('all-variations');
export const openVariationDetailsDialog = (options?: { variationId?: string; subTab?: string }) =>
  openDialogFromUrl('variation-details', options, ['subTab', 'claim', 'claimId']);
export const openAdjustmentDetailsDialog = (options?: { adjustmentId?: string; subTab?: string }) =>
  openDialogFromUrl('adjustment-details', options, ['subTab', 'claim', 'claimId']);

export const openHelpCenterDialog = () => openDialogFromUrl('help-center');
export const openAbandonContractDialog = () => openDialogFromUrl('abandon-contract');
export const openRevokeContractDialog = () => openDialogFromUrl('revoke-contract');

export const openSetCommencementDateDialog = () => openDialogFromUrl('set-commencement-date');
export const openSetCompletionDateDialog = () => openDialogFromUrl('set-completion-date');

export const reloadProfileAndCloseFlyout = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const dialog = searchParams.get('dialog');

  if (dialog && globalSettingsDialogs.has(dialog)) {
    closeFlyout();
  } else {
    closeNestedFlyout();
  }

  reloadProfile();
};

export const reloadContractAndCloseFlyout = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const dialog = searchParams.get('dialog');

  if (dialog && globalSettingsDialogs.has(dialog)) {
    closeFlyout();
  } else {
    closeNestedFlyout();
  }

  reloadContract();
};
